
































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import { Validations } from 'vuelidate-property-decorators'
import { validationMixin } from 'vuelidate'
import { required, ValidationRule } from 'vuelidate/lib/validators'
import TextInput from '@/app/ui/components/TextInput/index.vue'
import Button from '@/app/ui/components/Button/index.vue'
import FileInput from '@/app/ui/components/FileInput/index.vue'
import controller from '@/app/ui/controllers/BrandController'
import { EventBusConstants } from '@/app/infrastructures/misc'
import Modal from '@/app/ui/components/Modal/index.vue'
import LoadingOverlay from '@/app/ui/components/LoadingOverlay/index.vue'
import { Brand } from '@/domain/entities/Brand'

interface ValidationInterface {
  form: {
    name: {
      required: () => ValidationRule;
    },
    image: {
      required: boolean;
    }
  }
}

@Component({
  mixins: [validationMixin],
  components: {
    TextInput,
    Button,
    FileInput,
    Modal,
    LoadingOverlay,
  },
})
export default class CreateBrand extends Vue {
  controller = controller
  form: { name: string; image: Blob | null } = {
    name: '',
    image: null,
  }
  preview = ''
  confirmationModal = false
  successModal = false

  created(): void {
    controller.getBrandDetail(this.$route.params.id)
  }

  @Validations()
  validations(): ValidationInterface {
    return {
      form: {
        name: { required },
        image: { required: false },
      },
    }
  }

  @Watch('controller.brandDetail')
  updateForm(val: Brand): void {
    this.form = {
      name: <string>val.name,
      image: null
    }

    this.preview = <string>val.imageUrl
  }

  private changeImage(file: File): void {
    this.form.image = file
  }

  private updateBrand(): void {
    controller.updateBrand({
      id: Number(this.$route.params.id),
      name: <string>this.form.name,
      image: <Blob>this.form.image,
    })
  }

  private onCloseSuccessModal(): void {
    this.successModal = false
    this.$router.push({ name: 'BrandListPage' })
  }

  @Watch('controller.statusCreateUpdateBrand')
  onStatusCreateUpdateBrandChanged(status: string): void {
    if (status !== '') {
      if (status === EventBusConstants.UPDATE_BRAND_SUCCESS) {
        this.confirmationModal = false
        this.successModal = true
      }
    }
  }

  beforeDestroy(): void {
    controller.setStatusCreateUpdateBrand('')
  }
}
